class Accordions {
    init() {
        // Accordions
        const accordions = document.querySelector('.accordions');
        if (accordions) {
            var accordionLinks = document.querySelectorAll(".acc-link");
            // var accordionControls = document.querySelector(".acc-controls");
            // // accordion controls - open all / close all
            // accordionControls.onclick = function (e) {
            //     e.preventDefault();
            //     this.classList.toggle("open");
            //     if(this.classList.contains('open')){
            //         //this.innerHTML("Open all");
            //         accordionControls.innerHTML = "Close all";
            //         this.setAttribute('aria-expanded', 'true');
            //         this.classList.remove("close");
            //         this.classList.add("open");
            //         for (var i = 0; i < accordionLinks.length; i++) {
            //             accordionLinks[i].classList.remove("close");
            //             accordionLinks[i].classList.add("open");
            //             accordionLinks[i].setAttribute('aria-expanded', 'true');
            //         }                    
            //     }else{
            //         //this.innerHTML("Close all");
            //         accordionControls.innerHTML = "Open all";
            //         this.setAttribute('aria-expanded', 'false');                    
            //         this.classList.remove("open");
            //         this.classList.add("close");
            //         for (var i = 0; i < accordionLinks.length; i++) {
            //             accordionLinks[i].classList.remove("open");
            //             accordionLinks[i].classList.add("close");
            //             accordionLinks[i].setAttribute('aria-expanded', 'false');
            //         }
            //     }
            // }
            for (var i = 0; i < accordionLinks.length; i++) {
                var accordionLink = accordionLinks[i];
                accordionLink.onclick = function (e) {
                    e.preventDefault();
                    this.classList.toggle("open");
                    if(this.classList.contains('open')){
                        this.setAttribute('aria-expanded', 'true');
                    }else{
                        this.setAttribute('aria-expanded', 'false');
                    }                    
                };
            }
        }
    }
  
  }
  
  export default Accordions;